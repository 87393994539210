<template>
	<div >
		
		<div class="fix l-0 t-0 w100 z-i-10">
			<headertop />
			<headers/>
		</div>
		<div :class="$store.state.app.showguanggao?'h-184':'h-154'"></div>
		<div class="h-500 " :style="bigconfig.backgroundcolor">
			<img class="w100 h100" :src="bigconfig.activityimgurl"/>
		</div>
		
		<div class="goodslist pwidth" style="position: relative; top: -140px;">
			<goods :goods="zcgoodstlist" :isline="$store.state.app.tenantshoppingmallconfig.goodsvivewtype"/>
			
			
			<!-- 翻页 -->
			<div class="pwidth bg-f tc m-a p-20-0">
				<div id="Pagination">
					<el-pagination background layout="prev, pager, next" :page-size="pagesize" @current-change="currentchange" :current-page="pageno" :total="total"></el-pagination>
				</div>
			</div>
		</div>
		
		
	</div>
</template>

<script>
	export default{
		
		data() {
			return{
				pageno: 1,
				total: 1,
				pagesize: 20,
				bigconfig:[],
				zcgoodstlist:[]
			}
		},
		created() {
			this.getTenantid(()=>{
				this.getRichtext()
				this.getselectSpecialByPid()
				this.getquerygoodsbyspecial()
			})
		},
		methods:{
			getselectSpecialByPid(){
				this.requset('/b2bgoods/selectSpecialDetailByPid',{specialid:this.$route.query.id}).then((res)=> {
					this.bigconfig=res.data
				});
			},
			
			
			// 获取商品
			getquerygoodsbyspecial(){
				
				
				if(this.$route.query.id){
					this.requset('/b2bgoods/querycategorygoods',{
						specialid:this.$route.query.id,
						pageno: this.pageno,
						pagesize: this.pagesize,
						isloadbatch: true,
						}).then((res)=> {
						console.log('小转场',res)
						res.data.map(res=>{
							if(!res.batchInfoList)res['batchInfoList']=[]
						})
						this.zcgoodstlist=res.data
					});
				}
			},
			
			currentchange: function currentchange(e) {
				console.log(e);
				this.pageno = e;
				this.getgoodslist(0);
			},
			
			
			
		}
	}
</script>

<style>
</style>